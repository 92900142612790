/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 302px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  .address {
    clear: right;
  }
}
#banner-wrap {
  h1 {
    color: #ee6c25;
  }
  .cta-btn {
    min-width: 220px;
  }
  &.home {
    background: #eff0f2 url(../images/banner_bg-lp-sm.jpg) no-repeat right bottom / cover;
  }
  &.new-notary {
    background: #eff0f2 url(../images/banner_bg-new-sm.jpg) no-repeat right bottom / cover;
  }
  &.renew-notary {
    background: #eff0f2 url(../images/banner_bg-renew-sm.jpg) no-repeat 55% 65% / cover;
  }
  &.remote-online-notary {
    background: #eff0f2 url(../images/banner_bg-ron-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.naa {
    background: #eff0f2 url(../images/banner_bg-naa-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.renewal {
    .coupon {
      width: 100%;
      max-width: 350px;
      border: 3px dashed #333;
      background: #ffc;
      font-size: 18px;
      line-height: 1.3;
      strong {
        font-size: 30px;
        font-weight: 700;
      }
    }
    .card-action {
      width: 300px;
      height: 74px;
      background: #fff;
      border: 1px solid #0072bc;
      border-radius: 4px;
      text-align: left;
      background: #f7f1e9;
      background-image: linear-gradient(#fbf8f4, #efe4d6);
      zoom: 1;
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
      .info {
        font-weight: 700;
        color: #0072bc;
        font-family: 'Fjalla One', Impact;
        vertical-align: middle;
        margin-top: -12px;
      }
    }
    .cta-btn {
      max-width: 300px;
    }
  }
}
#intro-wrap,
#package-wrap {
  background: #fdf9f4;
}
.f-red {
  color: #b23624;
}
.card-product {
  .card-header {
    background-color: $theme-primary;
    a:hover {
      color: inherit;
    }
  }
}
.card-extra {
  background: #fff url(../images/section_bg.jpg) no-repeat top right;
}
#travelers-wrap {
  background-color: #f7f7f7;
}
#cta-wrap {
  background: #fdf9f4;
  h3 {
    color: #b23624;
  }
}
.accordion-custom {
  .accordion {
    margin-bottom: 10px;
    padding: 5px 10px;
    background-color: #f5f5f5;
    background-image: linear-gradient(#f3f3f3, #e6e6e6);
    border: 0;
    border-radius: 4px;
    .accordion-toggle {
      font-family: var(--font-family);
      color: #c43;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
ul.nolist {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #banner-wrap {
    &.renew-notary {
      background: #eff0f2 url(../images/banner_bg-renew.jpg) no-repeat 100% 100% / cover;
    }
    &.remote-online-notary {
      background: #eff0f2 url(../images/banner_bg-ron-lg.jpg) no-repeat 30% 50% / cover;
    }
    &.renewal {
      #banner {
        background: url(../images/renewal_bg.jpg) no-repeat 5px 20px;
      }
    }
  }
  #intro-wrap {
    .intro {
      background: transparent url(../images/notary-bg.jpg) no-repeat 20px 5px;
    }
  }
  #package-wrap {
    .notary-package {
      position: relative;
      z-index: 1;
      &:before {
        background: transparent url(../images/package_bg.jpg) no-repeat 100% -5px;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        content: '';
      }
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner-wrap {
    &.remote-online-notary {
      background: #eff0f2 url(../images/banner_bg-ron-lg.jpg) no-repeat 0 50% / cover;
    }
  }
  #intro-wrap {
    .intro {
      background-position: 15% 5px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 40%;
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    h1 {
      font-size: 2.074em;
    }
    &.home {
      background: #eff0f2 url(../images/banner_bg-lp.jpg) no-repeat 50% 65% / cover;
    }
    &.new-notary {
      background: #eff0f2 url(../images/banner_bg-new.jpg) no-repeat right bottom / cover;
    }
    &.renew-notary {
      background: #eff0f2 url(../images/banner_bg-renew-lg.jpg) no-repeat 80% 50% / cover;
    }
    &.remote-online-notary {
      background: #eff0f2 url(../images/banner_bg-ron-lg.jpg) no-repeat 50% 50% / cover;
    }
    &.naa {
      background: #eff0f2 url(../images/banner_bg-naa-lg.jpg) no-repeat 50% 50% / cover;
    }
    &.renewal {
      h1 {
        font-size: 2.441em;
      }
    }
  }
  #intro-wrap {
    .intro {
      background-position: 15% 5px;
    }
  }
  #package-wrap {
    .notary-package {
      &:before {
        opacity: 1;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    &.home {
      background: #eff0f2 url(../images/banner_bg-lp-lg.jpg) no-repeat 100% 50% / cover;
    }
    &.new-notary {
      background: #eff0f2 url(../images/banner_bg-new-lg.jpg) no-repeat right bottom / cover;
    }
    &.renewal {
      h1 {
        font-size: 3em;
      }
    }
  }
}
